import { CLEAR_FILTER, MERGE_FILTER, SET_FILTER } from '../types';
import * as R from 'ramda';

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_FILTER:
            return R.clone(initialState);
        case MERGE_FILTER:
            return R.mergeDeepRight(state, action.payload);
        case SET_FILTER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
