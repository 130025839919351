import { combineReducers } from 'redux';

import ContactReducer from './reducer/ContactReducer';
import FilterReducer from './reducer/FilterReducer';
import LayoutReducer from './reducer/LayoutReducer';
import ProjectReducer from './reducer/ProjectReducer';
import UserReducer from './reducer/UserReducer';

export default combineReducers({
    contact: ContactReducer,
    filter: FilterReducer,
    layout: LayoutReducer,
    project: ProjectReducer,
    user: UserReducer,
});
