import { CLEAR_PROJECT, MERGE_PROJECT, SET_PROJECT } from '../types';
import * as R from 'ramda';

const initialState = {
    filter: {},
    plot: {},
    project: {},
    scene: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_PROJECT:
            return R.clone(initialState);
        case MERGE_PROJECT:
            return R.mergeDeepRight(state, action.payload);
        case SET_PROJECT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
