import { CLEAR_CONTACT, MERGE_CONTACT, SET_CONTACT } from '../types';
import * as R from 'ramda';

const initialState = {
    open: false,
    form: '',

    field: {
        name: '',
        email: '',
        phone: '',
        message: '',
    },
    error: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_CONTACT:
            return R.clone(initialState);
        case MERGE_CONTACT:
            return R.mergeDeepRight(state, action.payload);
        case SET_CONTACT:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
