import React, { Suspense } from 'react';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
//import { PersistGate } from 'redux-persist/integration/react';
//import { store, persistor } from 'store/Store';
import { store } from 'store/Store';
import { BrowserRouter as Router } from 'react-router-dom';

const App = React.lazy(() => import('./App'));
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
            <Router>
                <Suspense fallback={<div className='loading' />}>
                    <App />
                </Suspense>
            </Router>
            {/* </PersistGate> */}
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
