export const CLEAR_CONTACT = 'CLEAR_CONTACT';
export const MERGE_CONTACT = 'MERGE_CONTACT';
export const SET_CONTACT = 'SET_CONTACT';

export const CLEAR_FILTER = 'CLEAR_FILTER';
export const MERGE_FILTER = 'MERGE_FILTER';
export const SET_FILTER = 'SET_FILTER';

export const CLEAR_LAYOUT = 'CLEAR_LAYOUT';
export const MERGE_LAYOUT = 'MERGE_LAYOUT';
export const SET_LAYOUT = 'SET_LAYOUT';

export const CLEAR_PROJECT = 'CLEAR_PROJECT';
export const MERGE_PROJECT = 'MERGE_PROJECT';
export const SET_PROJECT = 'SET_PROJECT';

export const CLEAR_USER = 'CLEAR_USER';
export const MERGE_USER = 'MERGE_USER';
export const SET_USER = 'SET_USER';
